var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rolemanage"},[_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('a-row',[_c('a-col',{attrs:{"span":4}}),_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":4,"offset":"16"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addRole}},[_vm._v(" + 新增 ")])],1)],1),_c('a-table',{staticStyle:{"margin-top":"16px"},attrs:{"rowKey":function (row) { return row.id; },"columns":_vm.table_col,"data-source":_vm.table_data,"loading":_vm.table_loading,"pagination":_vm.table_pagination},on:{"change":_vm.tableChange},scopedSlots:_vm._u([{key:"active",fn:function(text, record){return (record.modified)?[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.editRole(record)}}},[_vm._v("编辑")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"title":"确认删除该角色吗?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.removeRole(record)}}},[_c('a',{attrs:{"href":"#"}},[_vm._v("删除")])])]:undefined}}],null,true)}),_c('a-modal',{attrs:{"title":_vm.visible === 'add' ? '新增账号' : '编辑账号',"visible":_vm.visible && true},on:{"cancel":_vm.cancelModal,"ok":_vm.okModal}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'roleName',
						{
							rules: [
								{
									required: true,
									message: '请输入角色名称'
								}
							],
							initialValue: _vm.info.name
						}
					]),expression:"[\n\t\t\t\t\t\t'roleName',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '请输入角色名称'\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\tinitialValue: info.name\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],attrs:{"maxLength":16,"autocomplete":"off","placeholder":"请输入角色名称"}})],1),_c('a-form-item',{attrs:{"label":"权限配置"}},[_c('div',{staticClass:"rolemanage_config"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'roleConfig',
							{
								rules: [
									{
										required: true,
										message: '请选择权限配置'
									}
								],
								initialValue: (_vm.info.menuIdList || []).join(
									','
								)
							}
						]),expression:"[\n\t\t\t\t\t\t\t'roleConfig',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: '请选择权限配置'\n\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\tinitialValue: (info.menuIdList || []).join(\n\t\t\t\t\t\t\t\t\t','\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t]"}],staticClass:"rolemanage_config_input",attrs:{"autocomplete":"off","placeholder":"请选择权限配置"}}),_c('a-tree',{staticClass:"rolemanage_config_tree",attrs:{"checkable":"","defaultExpandAll":true,"tree-data":_vm.treeData,"replaceFields":_vm.replaceFields},model:{value:(_vm.checkedKeys),callback:function ($$v) {_vm.checkedKeys=$$v},expression:"checkedKeys"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }