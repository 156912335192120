<template>
	<div class="rolemanage">
		<h1 class="page_title">{{ $route.meta.title }}</h1>
		<a-row>
			<a-col :span="4"></a-col>
			<a-col :span="4" offset="16" style="text-align: right;">
				<a-button type="primary" @click="addRole"> + 新增 </a-button>
			</a-col>
		</a-row>
		<a-table
			style="margin-top: 16px"
			:rowKey="row => row.id"
			:columns="table_col"
			:data-source="table_data"
			:loading="table_loading"
			:pagination="table_pagination"
			@change="tableChange"
		>
			<template
				slot="active"
				slot-scope="text, record"
				v-if="record.modified"
			>
				<a href="javascript:;" @click="editRole(record)">编辑</a>
				<a-divider type="vertical"></a-divider>
				<a-popconfirm
					title="确认删除该角色吗?"
					ok-text="确定"
					cancel-text="取消"
					@confirm="removeRole(record)"
				>
					<a href="#">删除</a>
				</a-popconfirm>
			</template>
		</a-table>

		<a-modal
			:title="visible === 'add' ? '新增账号' : '编辑账号'"
			:visible="visible && true"
			@cancel="cancelModal"
			@ok="okModal"
		>
			<a-form
				:form="form"
				:label-col="{ span: 6 }"
				:wrapper-col="{ span: 16 }"
			>
				<a-form-item label="角色名称">
					<a-input
						:maxLength="16"
						autocomplete="off"
						placeholder="请输入角色名称"
						v-decorator="[
							'roleName',
							{
								rules: [
									{
										required: true,
										message: '请输入角色名称'
									}
								],
								initialValue: info.name
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="权限配置">
					<div class="rolemanage_config">
						<a-input
							class="rolemanage_config_input"
							autocomplete="off"
							placeholder="请选择权限配置"
							v-decorator="[
								'roleConfig',
								{
									rules: [
										{
											required: true,
											message: '请选择权限配置'
										}
									],
									initialValue: (info.menuIdList || []).join(
										','
									)
								}
							]"
						/>
						<a-tree
							class="rolemanage_config_tree"
							v-model="checkedKeys"
							checkable
							:defaultExpandAll="true"
							:tree-data="treeData"
							:replaceFields="replaceFields"
						/>
					</div>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import { authList, roleAuth, rolePage, roleRemove, roleCreate, roleUpdate} from "@/api/index";
	
	export default {
		name: "RoleManage",
		data() {
			return {
				form: this.$form.createForm(this),
				visible: false,
				info: {},
				table_col: [
					{
						title: "序号",
						dataIndex: "key",
						customRender: (text, record, index) => {
							return (
								(this.table_pagination.current - 1) *
									this.table_pagination.pageSize +
								(index + 1)
							);
						}
					},
					{ title: "角色名称", dataIndex: "name" },
					{ title: "创建时间", dataIndex: "createTime" },
					{
						title: "操作",
						dataIndex: "active",
						scopedSlots: {
							customRender: "active"
						}
					}
				],
				// tree组件参数
				replaceFields: {
					children: "childList",
					title: "name",
					key: "id"
				},
				treeData: [],
				checkedKeys: []
			};
		},
		created() {

		},
		mounted() {
			this.getTableList();
			this.getAllMenu();
		},
		methods: {
			getAllMenu() {
				authList({}).then(res => {
					this.treeData = res.data;
				});
			},
			getTableList() {
				this.table_loading = true;
				rolePage({
							current: this.table_pagination.current,
							pageSize: this.table_pagination.pageSize
						})
					.then(res => {
						this.table_data = res.data.list;
						this.table_pagination.total = res.data.total;
					})
					.catch(() => (this.table_data = []))
					.finally(() => (this.table_loading = false));
			},
			addRole() {
				this.info = {};
				this.visible = "add";
			},
			editRole(record) {
				this.info = record;
				roleAuth( { roleId: record.id } )
					.then(res => {
						this.treeData = res.data || [];
						this.checkedKeys = this.recursive(this.treeData);
					})
					.finally(() => {
						this.visible = "edit";
					});
			},
			// 递归获取被选中的菜单权限
			recursive(list, result = []) {
				let stack = [].concat(list);
				while (stack.length) {
					let item = stack.shift();
					if (item.childList && item.childList.length)
						stack.unshift(...item.childList);
					if (item.select) result.push(item.id);
				}
				return result;
			},
			removeRole(record) {
				roleRemove( {id: record.id }).then(() => {
					this.$message.success("删除角色成功");
					this.table_pagination.current = 1;
					this.getTableList();
				});
			},
			okModal() {
				this.form.setFieldsValue({
					roleConfig: this.checkedKeys.join(",")
				});
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
					if (!error) {					
						if(this.visible === "add"){
							roleCreate({
									name: values.roleName,
									menuIdList: this.checkedKeys
								})
								.then(() => {
									this.$message.success("操作成功");
									this.table_pagination.current = 1;
									this.getTableList();
								})
								.finally(() => this.cancelModal());
						}else{
							roleUpdate({
									id: this.info.id,
									name: values.roleName,
									menuIdList: this.checkedKeys
								})
								.then(() => {
									this.$message.success("操作成功");
									this.table_pagination.current = 1;
									this.getTableList();
								})
								.finally(() => this.cancelModal());
						}
					}
				});
			},
			cancelModal() {
				this.checkedKeys = [];
				this.form.resetFields();
				this.visible = false;
			}
		}
	};
</script>

<style lang="scss">
	.rolemanage {
		&_config {
			position: relative;
			overflow-y: auto;
			&_input {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				height: 100%;
			}
		}
	}
</style>
